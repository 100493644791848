<template>
  <div class="footer">
    <img class="whiteLogo" src="@/assets/mobile/whiteLogo.png" alt="" />
    <div class="contact">
      <div class="title">联系我们</div>
      <div class="des">
        公司地址：湖南省长沙市岳麓区环湖路1177号金茂广场南塔
        <div style="margin-left: 60px;">湘江集团大厦19层</div>
      </div>
      <!-- <div class="des">联系方式：guohao@hskgoo.com</div> -->
      <div class="des">联系方式：400-862-8806</div>
    </div>
    <div class="contact">
      <div class="title">更多合作</div>
      <div class="des" style="margin-bottom: 16px;" @click="isImg = true">
        商务合作
      </div>
      <div
        class="des"
        @click="toPage('https://mp.weixin.qq.com/s/YgpJofxRwCwEAD5L7W_XcA')"
      >
        加入我们
      </div>
    </div>
    <div class="img_lit flexVerticalCenter">
      <img
        class="img"
        v-for="(item, i) in imgList"
        :key="i"
        :src="item.img"
        alt=""
        @click="toPage(item.url)"
      />
    </div>
    <div class="company">
      <div class="des">
        Copyright © 2017-2024 | 聚趣教育科技有限公司版权所有 |
      </div>
      <div class="des">
        <a href="http://beian.miit.gov.cn">
          备案号：湘ICP备2021002148号-1
        </a>
      </div>
    </div>
    <div
      class="code_out flexVerticalCenter"
      v-if="isImg"
      @click="isImg = false"
    >
      <img
        class="code_img"
        mode="widthFix"
        src="https://qn.juquedu.com/cooperation_code.png"
        alt=""
        @click.stop="isImg = true"
      />
      <!-- <img
				class="code_img"
				mode="widthFix"
				src="../../assets/pc/pcFooter/code.png"
				alt=""
				@click.stop="
					downloadIamge(
						'https://qn.juquedu.com/00713106af84482aa04f06736cee8421',
						'二维码'
					)
				"
			/> -->
      <div class="code_text">长按图片保存</div>
      <div class="code_text">点击空白处关闭</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgList: [
        /*{
					img: require('@/assets/mobile/mobileFooter/bilibili.png'),
					url: 'https://space.bilibili.com/517809714'
				},*/
        {
          img: require('@/assets/mobile/mobileFooter/taobao.png'),
          url: 'https://juquedu.taobao.com/',
        },
        // {
        // 	img: require('@/assets/mobile/mobileFooter/weidian.png'),
        // 	url: '',
        // },
      ],
      isImg: false,
      longClick: 0,
      timeOutEvent: 0,
      code_img: 'https://qn.juquedu.com/00713106af84482aa04f06736cee8421',
      text: '',
    }
  },
  methods: {
    goToWebsite() {
      window.open('https://api.juquedu.com/website/index.html')
    },
    toPage(url) {
      window.open(url)
    },
    downloadIamge(imgsrc, name) {
      var image = new Image()
      // 解决跨域 Canvas 污染问题
      image.setAttribute('crossOrigin', 'anonymous')
      image.onload = function () {
        var canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        var context = canvas.getContext('2d')
        context.drawImage(image, 0, 0, image.width, image.height)
        var url = canvas.toDataURL('image/png') //得到图片的base64编码数据
        var a = document.createElement('a') // 生成一个a元素
        var event = new MouseEvent('click') // 创建一个单击事件
        a.download = name || 'photo' // 设置图片名称
        a.href = url // 将生成的URL设置为a.href属性
        a.dispatchEvent(event) // 触发a的单击事件
      }
      image.src = imgsrc
    },
  },
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  overflow-x: hidden;
  // height: 664px;
  background: #222326;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  color: #c5c5c5;
  font-size: 15px;
  .whiteLogo {
    padding: 32px 0 40px 15px;
    width: 36px;
  }
  .contact {
    width: 100%;
    padding: 0 15px 24px;
    border-bottom: 1px #39393d solid;
    margin-bottom: 24px;
    box-sizing: border-box;
  }
  .title {
    font-size: 15px;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 12px;
  }
  .des {
    font-size: 12px;
    font-weight: 400;
    color: #dcdde2;
    margin-bottom: 6px;
    a {
      color: #dcdde2;
    }
  }
  .img_lit {
    width: 100%;
    margin-bottom: 20px;
    .img {
      width: 50px;
      height: 50px;
      margin-right: 38px;
    }
    .img:last-child {
      // width: 70px;
      margin-right: 0;
    }
  }
  .company {
    text-align: center;
    margin-bottom: 24px;
  }
  .code_out {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #222326;
    z-index: 100;
    flex-direction: column;
    .code_img {
      width: 75%;
      -webkit-touch-callout: all !important; /*系统默认菜单被禁用*/
      -webkit-user-select: all !important; /*webkit浏览器*/
      -khtml-user-select: all !important; /*早期浏览器*/
      -moz-user-select: all !important; /*火狐*/
      -ms-user-select: all !important; /*IE10*/
      user-select: all !important;
    }
    .code_text {
      margin-top: 20px;
      font-size: 12px;
      color: #dcdde2;
    }
  }
}
</style>
