<template>
  <div class="nav">
    <img @click="gotohome" src="@/assets/mobile/logo.png" alt="" />
    <img @click="showPopup" src="@/assets/mobile/icon.png" alt="" />
    <van-popup
      v-model="show"
      position="right"
      closeable
      close-icon-position="top-right"
      :style="{ height: '100vh', width: '77%' }"
    >
      <div class="navList">
        <div
          :class="item.id == navIndex ? 'nav_item_out select' : 'nav_item_out'"
          v-for="(item, i) in navList"
          :key="i"
          @click="select(item)"
        >
          <div class="nav_item">
            {{ item.title }}
            <!-- 
							v-if="item.name == 'app' || item.name == 'product'" -->
            <img
              class="right"
              v-if="item.name == 'app'"
              src="../../assets/mobile/right.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </van-popup>
    <van-popup
      v-model="isApp"
      position="right"
      closeable
      close-icon-position="top-right"
      :style="{ height: '100vh', width: '77%' }"
    >
      <div class="navList">
        <div
          :class="item.id == appIndex ? 'nav_item_out select' : 'nav_item_out'"
          v-for="(item, i) in schoolAppList"
          :key="i"
          @click="open(item)"
        >
          <div class="nav_item">
            <img class="logo_img" :src="item.icon" alt="" />
            {{
              item.languageid == 100 || item.languageid == 101
                ? item.appName
                : item.appName + "GO"
            }}
          </div>
        </div>
      </div>
    </van-popup>
    <van-popup
      v-model="isWebsite"
      position="right"
      closeable
      close-icon-position="top-right"
      :style="{ height: '100vh', width: '77%' }"
    >
      <div class="navList">
        <div
          :class="item.id == websiteIndex ? 'nav_item_out select' : 'nav_item_out'"
          v-for="(item, i) in websiteList"
          :key="i"
          @click="open2(item)"
        >
          <div class="nav_item">
            <img class="logo_img" :src="item.img" alt="" />
            {{ item.title }}
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
// const axios = require('axios')
import { navList } from "../../utils/navList.js";
export default {
  data() {
    return {
      show: false,
      isApp: false,
      isWebsite: false,
      navIndex: 1,
      navList: [
        { id: 1, title: "首页", name: "mobileHome" },
        { id: 2, title: "公司动态", name: "mobileNews" },
        { id: 3, title: "小语种高考", name: "mobileGaokao" },
        { id: 4, title: "聚趣网校", name: "product" },
        { id: 5, title: "联系我们", name: "mobileContact" },
        { id: 6, title: "下载APP", name: "app" },
      ],
      appIndex: 0,
      schoolAppList: [],
      websiteIndex: 0,
      websiteList: [
        {
          id: 1,
          title: "德语",
          name: "german",
          img: require("@/assets/mobile/german.png"),
          url: "https://german.juquedu.com/school/index.html",
        },
        {
          id: 2,
          title: "法语",
          name: "french",
          img: require("@/assets/mobile/french.png"),
          url: "https://fr.juquedu.com/school/index.html",
        },
        {
          id: 3,
          title: "韩语",
          name: "korean",
          img: require("@/assets/mobile/korean.png"),
          url: "https://korean.juquedu.com/school/index.html",
        },
      ],
    };
  },
  watch: {
    $route: {
      handler() {
        this.getNavItem("watch");
      },
      // 深度观察监听
      deep: true,
    },
  },
  created() {
    this.getNavItem();
    this.getSchoolAppList();
  },
  methods: {
    getNavItem(type) {
      if (sessionStorage.item) {
        var item = sessionStorage.item;
        item = JSON.parse(item);
        console.log(item);
        this.navIndex = item.id;
        if (item.name == "mobileCourseDetails") {
          this.navList = navList.filter(function (item) {
            return item.title == "课程";
          });
          this.isBackground = true;
        } else {
          this.navList = navList.filter(function (item) {
            return item.title != "课程";
          });
        }
        if (type == "watch") {
          return;
        }
        if (item.name != "mobileHome") {
          if (sessionStorage.paramsObj) {
            this.$router.push({
              name: item.name,
              query: JSON.parse(sessionStorage.paramsObj),
            });
          } else {
            this.$router.push({ name: item.name });
          }
        }
      }
    },
    // 获取app配置
    async getSchoolAppList() {
      var params = new URLSearchParams();
      params.append("page", 0);
      params.append("size", 99999);
      params.append("sort", "sort,asc");
      const { data: res } = await this.$http.post("school/schoolApp/un/getlist", params);
      if (res.status == 1001) {
        res.data.content.forEach((item) => {
          var languageid = item.languageid;
          if (languageid == 4) {
            sessionStorage.setItem("germanApp", JSON.stringify(item));
            item.url = "https://german.juquedu.com/website/index.html";
          }
          if (languageid == 5) {
            sessionStorage.setItem("frenchApp", JSON.stringify(item));
            item.url = "https://fr.juquedu.com/website/index.html";
          }
          if (languageid == 3) {
            sessionStorage.setItem("japaneseApp", JSON.stringify(item));
            item.url = "https://api.juquedu.com/website/index.html";
          }
          if (languageid == 2) {
            sessionStorage.setItem("spanishApp", JSON.stringify(item));
            item.url = "https://spain.juquedu.com/website/index.html";
          }
          if (languageid == 1) {
            sessionStorage.setItem("koreanApp", JSON.stringify(item));
            item.url = "https://korean.juquedu.com/website/index.html";
          }
          if (languageid == 6) {
            sessionStorage.setItem("ieltsApp", JSON.stringify(item));
            item.url = "https://ielts.juquedu.com/download/index.html";
          }
          if (languageid == 100) {
            sessionStorage.setItem("schoolNetApp", JSON.stringify(item));
            item.url = "https://ke.juquedu.com/website/index.html";
          }
          if (languageid == 101) {
            sessionStorage.setItem("germanWordApp", JSON.stringify(item));
            item.url = "https://germanword.juquedu.com/#/index";
          }
        });
        sessionStorage.setItem("schoolAppMo", JSON.stringify(res.data.content));
        this.schoolAppList = JSON.parse(sessionStorage.getItem("schoolAppMo"));
        this.appIndex = this.schoolAppList[0].id;
      }
    },
    showPopup() {
      this.show = true;
    },
    select(item) {
      console.log(item);
      if (item.name != "app") {
        if (item.name == "product") {
          // this.isWebsite = true
          // if (this.$route.path != '/mobileHome') {
          // 	this.gotohome()
          // }
          window.open("https://ke.juquedu.com/index.html");
        } else {
          this.show = false;
          this.navIndex = item.id;
          sessionStorage.item = JSON.stringify(item);
          this.$router.push({ name: item.name });
        }
      } else {
        this.isApp = true;
        console.log(this.$route.path);
        if (this.$route.path != "/mobileHome") {
          this.gotohome();
        }
      }
    },
    open(item) {
      window.open(item.url);
      sessionStorage.appIndex = item.id;
      this.appIndex = sessionStorage.appIndex;
    },
    open2(item) {
      window.open(item.url);
      sessionStorage.websiteIndex = item.id;
      this.websiteIndex = sessionStorage.websiteIndex;
    },
    gotohome() {
      if (this.$route.path != "/mobileHome") {
        this.$router.push("/mobileHome");
        this.navIndex = 1;
      } else {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  background: #fff;
  position: sticky;
  top: 0;
  display: flex;
  padding: 10px 20px;
  justify-content: space-between;
  z-index: 999;
  border-bottom: solid 1px #e8e8e8;
  img {
    width: 48px;
    height: 48px;
  }
  .navList {
    margin-top: 92px;
    line-height: 72px;
    color: #333333;
    font-size: 18px;
    font-weight: bold;
    .nav_item_out {
      padding-left: 36px;
      box-sizing: border-box;
      .nav_item {
        // padding-left: 59px;
        height: 72x;
        border-bottom: 1px solid #ebedef;
        display: flex;
        align-items: center;
        .right {
          width: 9px;
          height: 16px;
          margin-left: 117px;
        }
        .logo_img {
          width: 25px;
          height: 25px;
          margin-right: 10px;
          border-radius: 6px;
        }
      }
    }
    // div:active {
    // 	background: #e8e8e8;
    // }
    .select {
      background-color: #e8e8e8;
      // padding-left: 19px;
    }
  }
}
/deep/ .van-popup__close-icon {
  color: #121212;
}
</style>
